@import "./bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~flag-icons/css/flag-icons.min.css";

body{
    width: 100%;
    overflow-x: hidden;
    font-family: "Open Sans",Arial,Verdana,Helvetica,sans-serif;
}

.cursor-pointer{
    cursor: pointer;
}
